import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TableConfig = _resolveComponent("TableConfig")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableFooter = _resolveComponent("TableFooter")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBody, null, {
      tableConfig: _withCtx(() => [
        _createVNode(_component_TableConfig, {
          border: $setup.tableConfig.border,
          "onUpdate:border": _cache[0] || (_cache[0] = ($event: any) => (($setup.tableConfig.border) = $event)),
          stripe: $setup.tableConfig.stripe,
          "onUpdate:stripe": _cache[1] || (_cache[1] = ($event: any) => (($setup.tableConfig.stripe) = $event)),
          onRefresh: $setup.doRefresh
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              icon: "PlusIcon",
              onClick: $setup.addList
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增 ")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["border", "stripe"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: $setup.dataList,
          "header-cell-style": $setup.tableConfig.headerCellStyle,
          size: $setup.tableConfig.size,
          stripe: $setup.tableConfig.stripe,
          border: $setup.tableConfig.border,
          height: $setup.tableConfig.height
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "序号",
              prop: "ID",
              width: "85"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "排序",
              prop: "Sort",
              width: "85"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "分类名称",
              prop: "Name"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "分类描述",
              prop: "Description"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "操作"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  plain: "",
                  type: "primary",
                  size: "small",
                  onClick: ($event: any) => ($setup.onUpdateItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("编辑")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                _createVNode(_component_el_button, {
                  plain: "",
                  type: "danger",
                  size: "small",
                  onClick: ($event: any) => ($setup.onDeleteItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("删除")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
          [_directive_loading, $setup.tableLoading]
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_TableFooter, {
          ref: "tableFooter",
          onRefresh: $setup.doRefresh,
          onPageChanged: $setup.doRefresh
        }, null, 512 /* NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.dialogVisible) = $event)),
      title: $setup.dialogTitle
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.onSubmitForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("确定")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.onResetForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: $setup.ruleForm,
          rules: $setup.rules,
          ref: "ruleFormRef",
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "排序",
              prop: "sort"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: $setup.ruleForm.sort,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.ruleForm.sort) = $event)),
                  min: 0
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "分类名称",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElInput"], {
                  modelValue: $setup.ruleForm.name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.ruleForm.name) = $event)),
                  placeholder: "请输入分类名称"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "分类详情",
              prop: "description"
            }, {
              default: _withCtx(() => [
                _createCommentVNode(" <RichTextEditor ref=\"richTextEditor\" :height=\"400\" style=\"width: 100%;\" /> "),
                _createCommentVNode(" 多行文本 "),
                _createVNode($setup["ElInput"], {
                  type: "textarea",
                  modelValue: $setup.ruleForm.description,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.ruleForm.description) = $event)),
                  rows: 4,
                  placeholder: "请输入内容"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ]))
}